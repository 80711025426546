<template>
  <div id="menu-create-first" class="menu-create-first">
    <v-menu-management />
  </div>
</template>

<script>
import confirmBeforeLeaveMenu from "../../../mixins/confirmBeforeLeaveMenu";

import VMenuManagement from "../../../components/menus/VMenuManagement";

export default {
  name: "MenuCreateFirst",

  middleware: ["auth", "first-visit"],

  mixins: [confirmBeforeLeaveMenu],

  metaInfo() {
    return { title: "Menu Create" };
  },

  components: {
    VMenuManagement,
  },

  mounted() {
    this.$store.dispatch("menu/fetchDefaultMenu");
  },
};
</script>

<style lang="scss" scoped>
.menu-create-first {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 88px);
}

@media screen and (max-width: 576px) {
  .menu-create-first {
    padding-top: 60px;
  }
}
</style>
